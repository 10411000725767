import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import abstraction from "../../assets/quizMain.png";
import SectionStyled from "./SectionStyled";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { setIsContactFormOpen } from "@root/contactForm";
import { useUserStore } from "@root/user";

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
  const userId = useUserStore((state) => state.userId);
  const navigate = useNavigate();

  const location = useLocation();
  const tryItForFreeonClick = () =>
    userId ? navigate("/list") : setIsContactFormOpen(true);

  return (
    <SectionStyled
      tag={"section"}
      bg={"#f2f3f7"}
      mwidth={"1160px"}
      sxsect={{
        height: isMobile ? "702px" : isTablet ? "823px" : "660px",
      }}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: isMobile
          ? "300px 16px 0 16px"
          : isTablet
            ? "485px 40px 0 40px"
            : isSmallMonitor
              ? "0 20px"
              : 0,
        marginBottom: isMobile ? "55px" : isTablet ? 0 : "55px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <Typography variant="h2">Pena Quiz</Typography>
        <Box
          sx={{
            maxWidth: isTablet ? "715px" : "420px",
            minHeight: "64px",
          }}
        >
          <Typography variant="body1">
            Помогаем посетителю оставить заявку.{" "}
            <br style={{ display: isTablet ? "flex" : "none" }} /> Готовые
            шаблоны квизов с легкой установкой на любой сайт
          </Typography>
          {/* <Typography variant="body1">
            Помогаем посетителю оставить заявку.{" "}
            <br style={{ display: isTablet ? "flex" : "none" }} /> Готовые
            шаблоны квизов с легкой установкой на любой сайт и социальные сети
          </Typography> */}
        </Box>

        <Button
          component={Link}
          to={"/signin"}
          state={{ backgroundLocation: location }}
          variant="contained"
        >
          Попробуйте бесплатно
        </Button>

        <Box
          component={"img"}
          src={abstraction}
          sx={{
            position: "absolute",
            bottom: isMobile
              ? undefined
              : isTablet
                ? "270px"
                : isSmallMonitor
                  ? "-146px"
                  : "-145px",
            maxWidth: isMobile ? "403px" : isSmallMonitor ? "500px" : "810px",
            width: isMobile ? "100%" : undefined,
            left: isMobile
              ? "-20px"
              : isTablet
                ? "54px"
                : isSmallMonitor
                  ? "460px"
                  : "544px",
            top: isMobile ? "-345px" : undefined,
          }}
        />
      </Box>
    </SectionStyled>
  );
}
