import { QuestionBranchingRuleMain, QuestionVariant } from "@frontend/squzanswerer";
import { nanoid } from "nanoid";

export interface UntypedQuizQuestion {
  type: null;
  id: string;
  quizId: number;
  title: string;
  description: string;
  expanded: boolean;
  deleted: boolean;
}

export function createBranchingRuleMain(targetId: string, parentId: string): QuestionBranchingRuleMain {
  return {
    next: targetId,
    or: false,
    rules: [
      {
        question: parentId,
        answers: [],
      },
    ],
  };
}

export function createQuestionVariant(): QuestionVariant {
  return {
    id: nanoid(),
    answer: "",
    extendedText: "",
    hints: "",
    originalImageUrl: "",
  };
}
