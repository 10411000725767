import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Fade, Typography, Zoom, useMediaQuery, useTheme } from "@mui/material";
import SectionStyled from "./SectionStyled";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Notebook from "../../assets/LandingPict/notebook";
import BigBlock from "./images/bigblock.png";
import businessTasks2 from "./images/businessTasks2.png";
import businessTasks3 from "./images/businessTasks3.png";
import businessTasks4 from "./images/businessTasks4.png";
import Button from "@mui/material/Button";
// import Note from './image/note.png'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import CalendarW from './image/calendar-W.svg'
// import CalendarP from './image/calendar-P.svg'
import CalendarIcon from "../../assets/LandingPict/calendarIcon";
import { PieСhartIcon } from "@icons/PieСhartIcon";
import { SegmentationIcon } from "@icons/SegmentationIcon";
import { TestingIcon } from "@icons/TestingIcon";
import { EcommerceIcon } from "@icons/EcommerceIcon";
import { DesktopQuizCard } from "./DesktopQuizCard";
import { MobileQuizCard } from "./MobileQuizCard";

// const BoxUse = styled('div')(({ theme }) => ({
//     [theme.breakpoints.down('md')]: {
//         justifyContent: 'center',
//     },
// }));
//
// const BoxButtons = styled('div')(({ theme }) => ({
//     [theme.breakpoints.down('md')]: {
//         flexDirection: 'column',
//         alignItems: 'center',
//         height: '100px',
//         width: '260px'
//     },
// }));

const BoxQuiz = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "530px",
  },
}));

const businessTasks = {
  research: {
    header: "Исследуйте аудиторию и проверяйте гипотезы",
    text: "Опросник помогает проверять гипотезы и принимать грамотные решения основанные на данных. Создавайте опросы, собирайте обратную связь и анализируйте ответы в удобной аналитике в личном кабинете. ",
    image: BigBlock,
  },
  segmentation: {
    header:
      "Лучше узнайте свою аудиторию, сегментируйте и собирайте заявки с помощью квиза",
    text: "Квиз легко создать самому за 10 минут: настроить логику вопросов, результаты и получить готовую ссылку.",
    image: businessTasks2,
  },
  testing: {
    header:
      "Создавайте интерактивные тесты, показывайте правильные и неправильные ответы",
    text: "Тест в игровой форме увеличит вовлеченность пользователей. Создать его легко: задайте вопрос и отметьте правильные варианты ответов.",
    image: businessTasks3,
  },
  eсommerce: {
    header:
      "Консультируйте клиентов по ассортименту и помогите определиться с выбором",
    text: "Тест в игровой форме увеличит вовлеченность пользователей. Создать его легко: задайте вопрос и отметьте правильные варианты ответов.",
    image: businessTasks4,
  },
};

function Quiz() {
  return (
    <BoxQuiz
      sx={{
        maxWidth: "360px",
        height: "462px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        // justifyContent: 'space-around',
        alignItems: "center",
        padding: "20px 20px 10px 20px",
        marginTop: "36px",
        gap: "25px",
        boxSizing: "border-box",
      }}
    >
      <Box>
        <Notebook />
      </Box>
      <Box
        sx={{
          minHeight: "86px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Обучение английскому</Typography>
        <Typography color={"#4D4D4D"}>
          Квиз в сторис для блогера преподавателя английского языка.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={600}>период:</Typography>
          <Typography fontWeight={600}>трафик:</Typography>
          <Typography fontWeight={600}>результат:</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Typography>24 часа</Typography>
          <Typography>Сторисы</Typography>
          <Typography>386 заявок</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Link
          href="#"
          sx={{
            color: "#7E2AEA",
            textUnderlineOffset: "7px",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "20px",
            textAlign: "center",
          }}
        >
          Подробнее →
        </Link>
      </Box>
    </BoxQuiz>
  );
}

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const [task, setTask] = useState<
    "research" | "segmentation" | "testing" | "eсommerce"
  >("research");
  const [open, setOpen] = useState<boolean>(false);
  return (
    <SectionStyled
      tag={"section"}
      bg={"#f2f3f7"}
      mwidth={"1160px"}
      sxsect={{
        minHeight: isMobile ? "auto" : "809px",
        alignItems: "flex-start",
      }}
      sxcont={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        padding: isMobile
          ? "70px 16px 70px 16px"
          : isTablet
            ? "16px 40px 50px 40px"
            : "81px 0 50px 0",
      }}
    >
      {/*<Box*/}
      {/*    sx={{*/}
      {/*        height: '50%',*/}
      {/*        display: 'flex',*/}
      {/*        flexDirection: 'column',*/}
      {/*        justifyContent: 'space-around',*/}
      {/*        marginTop: '34px',*/}
      {/*        width:'100%',*/}
      {/*    }}*/}
      {/*>*/}
      {/*    <BoxUse*/}
      {/*        sx={{*/}
      {/*            display: 'flex',*/}
      {/*            flexWrap: 'wrap',*/}
      {/*            justifyContent: 'space-between',*/}
      {/*            marginTop: '20px',*/}
      {/*        }}*/}
      {/*    >*/}
      {/*        <Box*/}
      {/*            sx={{*/}
      {/*                display: 'flex',*/}
      {/*                flexDirection: 'column',*/}
      {/*                gap: '20px'*/}

      {/*            }}*/}
      {/*        >*/}
      {/*            <Typography variant='h6' fontSize={'36px'}>Как используют квиз</Typography>*/}
      {/*            <Typography marginBottom={'10px'} fontSize={'18px'}>В крупном и малом бизнесе</Typography>*/}
      {/*        </Box>*/}
      {/*        <BoxButtons*/}
      {/*            sx={{*/}
      {/*                display: 'flex',*/}
      {/*                justifyContent: 'space-between',*/}
      {/*                alignItems: 'center',*/}
      {/*                flexWrap: 'wrap',*/}
      {/*                marginBottom: '40px',*/}
      {/*                maxWidth: '357px',*/}
      {/*                width: '100%',*/}
      {/*            }}*/}
      {/*        >*/}
      {/*            <Button variant='outlined'*/}
      {/*                     sx={{*/}
      {/*                        color: '#000000',*/}
      {/*                         borderColor: '#000000',*/}
      {/*                     }}*/}
      {/*            >*/}
      {/*                Посмотреть все кейсы*/}
      {/*            </Button>*/}
      {/*            <Box>*/}
      {/*                <Button variant='contained'*/}
      {/*                        sx={{*/}
      {/*                            height: '44px',*/}
      {/*                            width: '44px',*/}
      {/*                            borderRadius: '50%',*/}
      {/*                            minWidth: '44px',*/}
      {/*                            marginRight: '10px',*/}
      {/*                        }}*/}
      {/*                ><ArrowBackIcon/></Button>*/}
      {/*                <Button variant='contained'*/}
      {/*                        sx={{*/}
      {/*                            height: '44px',*/}
      {/*                            width: '44px',*/}
      {/*                            borderRadius: '50%',*/}
      {/*                            minWidth: '44px',*/}
      {/*                        }}*/}
      {/*                ><ArrowForwardIcon/></Button>*/}
      {/*            </Box>*/}
      {/*        </BoxButtons>*/}
      {/*    </BoxUse>*/}
      {/*    <BoxUse*/}
      {/*        sx={{*/}
      {/*            display: 'flex',*/}
      {/*            justifyContent: 'space-between',*/}
      {/*            flexWrap: 'wrap',*/}
      {/*            minHeight: '530px',*/}
      {/*            marginTop: '6px',*/}
      {/*        }}*/}
      {/*    >*/}
      {/*        <Quiz/>*/}
      {/*        <Quiz/>*/}
      {/*        <Quiz/>*/}
      {/*    </BoxUse>*/}
      {/*</Box>*/}
      <Box
        sx={{
          height: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            maxWidth: isMobile ? "273px" : undefined,
          }}
        >
          <Typography
            variant="h6"
            fontSize={"36px"}
            maxWidth={"420px"}
            lineHeight={"42.66px"}
          >
            Что еще можно делать с Опросником
          </Typography>
          <Typography fontSize={"18px"}>
            Изящное решение исследовательских и бизнес-задач
          </Typography>
        </Box>
        {!isTablet && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "20px",
              marginTop: "60px",
            }}
          >
            <Button
              onClick={() => setTask("research")}
              sx={{
                width: "275px",
                height: "66px",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                gap: "15px",
                justifyContent: "flex-start",

                textTransform: "none",
                backgroundColor: task !== "research" ? "white" : "#7e2aea",
                color: task !== "research" ? "black" : "#ffffff",
                "&:hover": {
                  backgroundColor: task === "research" ? "#581CA7" : "#e9eaed",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  minWidth: "36px",
                  height: "36px",
                  background: task !== "research" ? "#7E2AEA" : "white",
                  display: " flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PieСhartIcon
                  style={{
                    color: task !== "research" ? "white" : "#7E2AEA",
                    fontSize: "24px",
                  }}
                />
              </Box>

              <Typography>Исследования</Typography>
            </Button>
            <Button
              onClick={() => setTask("segmentation")}
              sx={{
                width: "275px",
                height: "66px",
                borderRadius: "12px",
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "flex-start",

                textTransform: "none",
                backgroundColor: task !== "segmentation" ? "white" : "#7e2aea",
                color: task !== "segmentation" ? "black" : "#ffffff",
                "&:hover": {
                  backgroundColor:
                    task === "segmentation" ? "#581CA7" : "#e9eaed",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  minWidth: "36px",
                  height: "36px",
                  background: task !== "segmentation" ? "#7E2AEA" : "white",
                  display: " flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SegmentationIcon
                  style={{
                    color: task !== "segmentation" ? "white" : "#7E2AEA",
                    fontSize: "24px",
                  }}
                />
              </Box>

              <Typography>Сегментация</Typography>
            </Button>
            <Button
              onClick={() => setTask("testing")}
              sx={{
                width: "275px",
                height: "66px",
                borderRadius: "12px",
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "flex-start",
                textTransform: "none",
                backgroundColor: task !== "testing" ? "white" : "#7e2aea",
                color: task !== "testing" ? "black" : "#ffffff",
                "&:hover": {
                  backgroundColor: task === "testing" ? "#581CA7" : "#e9eaed",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  minWidth: "36px",
                  height: "36px",
                  background: task !== "testing" ? "#7E2AEA" : "white",
                  display: " flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TestingIcon
                  style={{
                    color: task !== "testing" ? "white" : "#7E2AEA",
                    fontSize: "24px",
                  }}
                />
              </Box>

              <Typography>Тестирование</Typography>
            </Button>
            <Button
              onClick={() => setTask("eсommerce")}
              sx={{
                width: "275px",
                height: "66px",
                borderRadius: "12px",
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "flex-start",

                textTransform: "none",
                backgroundColor: task !== "eсommerce" ? "white" : "#7e2aea",
                color: task !== "eсommerce" ? "black" : "#ffffff",
                "&:hover": {
                  backgroundColor: task === "eсommerce" ? "#581CA7" : "#e9eaed",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  minWidth: "36px",
                  height: "36px",
                  background: task !== "eсommerce" ? "#7E2AEA" : "white",
                  display: " flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EcommerceIcon
                  style={{
                    color: task !== "eсommerce" ? "white" : "#7E2AEA",
                    fontSize: "24px",
                  }}
                />
              </Box>
              <Typography>E-commerce</Typography>
            </Button>
          </Box>
        )}

        {isTablet ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              mt: isMobile ? "40px" : "50px",
            }}
          >
            <MobileQuizCard
              header={businessTasks.research.header}
              image={businessTasks.research.image}
              text={businessTasks.research.text}
              textIcon="Исследования"
              icon={
                <PieСhartIcon
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                />
              }
            />

            <MobileQuizCard
              header={businessTasks.segmentation.header}
              image={businessTasks.segmentation.image}
              text={businessTasks.segmentation.text}
              textIcon="Сегментация"
              icon={
                <SegmentationIcon
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                />
              }
            />
            <MobileQuizCard
              header={businessTasks.testing.header}
              image={businessTasks.testing.image}
              text={businessTasks.testing.text}
              textIcon="Тестирование"
              icon={
                <TestingIcon
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                />
              }
            />
            <MobileQuizCard
              header={businessTasks.eсommerce.header}
              image={businessTasks.eсommerce.image}
              text={businessTasks.eсommerce.text}
              textIcon="E-commerce"
              icon={
                <EcommerceIcon
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                />
              }
            />
          </Box>
        ) : (
          <>
            <DesktopQuizCard
              isOpen={task === "research"}
              header={businessTasks.research.header}
              image={businessTasks.research.image}
              text={businessTasks.research.text}
            />

            <DesktopQuizCard
              isOpen={task === "segmentation"}
              header={businessTasks.segmentation.header}
              image={businessTasks.segmentation.image}
              text={businessTasks.segmentation.text}
            />

            <DesktopQuizCard
              isOpen={task === "testing"}
              header={businessTasks.testing.header}
              image={businessTasks.testing.image}
              text={businessTasks.testing.text}
            />

            <DesktopQuizCard
              isOpen={task === "eсommerce"}
              header={businessTasks.eсommerce.header}
              image={businessTasks.eсommerce.image}
              text={businessTasks.eсommerce.text}
            />
          </>
        )}
      </Box>
    </SectionStyled>
  );
}
